<template lang="">
    <div class="wrapper">
        <el-breadcrumb separator="/" class="el-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/SupplyandDemand' }">行业供求市场</el-breadcrumb-item>
            <el-breadcrumb-item>需求发布</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="concent">
            <!-- 需求发布 -->
            <section class="section1">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">需求发布</div>
                    </div>
                    <div class="center clearfix">
                        <div class="fl w50 item">
                            <p>需求名称</p>
                            <el-input placeholder="请输入"></el-input>
                        </div>
                        <div class="fl w50 item">
                            <p>物资类型</p>
                            <el-input placeholder="请输入需要的物资所属类型"></el-input>
                        </div>
                        <div class="fl w50 item">
                            <p>规格类型</p>
                            <el-input placeholder="请输入需求具体规格型号"></el-input>
                        </div>
                        <div class="fl w50 item">
                            <p>需求数量</p>
                            <el-input placeholder="请输入"></el-input>
                        </div>
                        <div class="fl w50 item">
                            <p>需求价格</p>
                            <el-input placeholder="请输入需求满足后预期支付的价格"></el-input>
                        </div>
                        <div class="fl w50 item">
                            <p>截止时间</p>
                            <el-date-picker type="date" placeholder="选择日期" class="w100">
                            </el-date-picker>
                        </div>
                        <div class="fl w100">
                            <p>需求说明</p>
                            <el-input type="textarea" placeholder="请输入需求具体说明"></el-input>
                        </div>
                    </div>
                </div>
            </section>

            <p class="return" style="padding-left: 20px;padding-bottom: 20px;">
                <el-button @click="goback">
                    保存
                </el-button>
                <el-button @click="goback">
                    保存并发布
                </el-button>
            </p>
        </div>
    </div>
</template>
<script>
    // 
    export default {
        name: 'demandDetails',
        data() {
            return {

            };
        },
        methods: {
            goback() {
                this.$router.go(-1)
            },

        },
        mounted() {

        },
        created() {

        },
    };
</script>
<style lang="scss" scoped>
    * {
        box-sizing: border-box;
    }

    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .tc {
        text-align: center;
    }

    .clearfix::after {
        content: '';
        display: block;
        clear: both;
    }

    .w50 {
        width: 50%;
    }

    .w100 {
        width: 100%;
    }

    .wrapper {
        padding-left: 83px;
        padding-right: 83px;
        padding-bottom: 15px;
        margin: 0 auto;
        min-width: 1366px;

        section {
            margin-bottom: 16px;
        }

        .concent {
            .section1 {
                min-height: 539px;
            }

        }
    }

    

    /deep/ .el-textarea__inner {
        height: 120px;
    }

    /deep/ .el-button {
        background: #024A8F;
        color: #fff;
        border: none;
    }

    .dataOverview .right-box .list-item {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
    }

    .dataOverview .right-box .list-item:nth-last-of-type(1) {
        margin-bottom: 0;
    }

    .chartView {
        padding: 16px 20px 24px;
        background-color: #fff;
    }

    .chartView .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 16px;
    }

    .chartView .item {
        margin-bottom: 22px;
    }


    .chartView .item-box .item-top {
        border-top: 2px solid #4393f8;
        margin-bottom: 16px;
        position: relative;
    }

    .chartView .item-box .item-top .top-bg {
        height: 0px;
        width: 172px;
        border-top: 40px solid #4393f8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -96px;
    }


    .chartView .center p {
        line-height: 30px;
        color: #666;
    }


    .chartView .center .w50:nth-child(2n) {
        padding-left: 32px;
    }

    .chartView .center .w50:nth-child(2n-1) {
        padding-right: 32px;
    }

    .chartView .item-box .item-title {
        height: 40px;
        line-height: 40px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .concent .title {
        font-family: 'MicrosoftYaHei-Bold';
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        position: relative;
        padding-left: 14px;
    }

    .concent .title:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        left: 0;
    }
</style>